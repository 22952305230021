import axios from 'axios';
import qs from 'qs';
import {NotificationContainer, NotificationManager} from 'react-notifications';


export function escapeHtml(text) {
        return text
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export function rescueHtml(text) {
    return text
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, "\"")
    .replace(/&#039;/g, "'");
}

export function deleteOrganisme(id_organisme) {
    const params = {
        "deleteOrganisme": true,
        "id_organisme": id_organisme
    }
    axios.post('https://app-slfinance.fr/api/organismes.php', qs.stringify(params))
    .then(async (res) => {
        if (res.data['status'] === 1) {
            deleteBudgets(id_organisme)
        }
    })
    .catch((err) => {
        console.log(err)
    })
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function deleteBudgets(id_organisme) {
    const params = {
        "deleteBudgets": true,
        "id_organisme": id_organisme
    }
    console.log(params)
    axios.post('https://app-slfinance.fr/api/budgets.php', qs.stringify(params))
    .then(async (res) => {
        console.log(res.data)
    })
    .catch((err) => {
        console.log(err)
    })
}

export function addSpace(num, withoutdec = false)
{
    var neg = ''
    if (num === null) {
        num = 0;
    }
    
    if (num.toString()[0] === '-') {
        neg = '-'
        num = num * -1
    }
    num = num.toString().split("").reverse().join("")
    var count = 0
    var index = num.indexOf(".")
    if (index === -1) {
        index = 0
        count = 1
    }
    while(num[index])
    {
        if (count !== 0 && count % 4 === 0) {
            num = num.slice(0, index) + " " + num.slice(index)
        }
        count++;
        index++;
    }

    num = neg+''+num.toString().split("").reverse().join("").replace('.', ',')
    if (num !== '' && !num.includes(',') && !withoutdec) {
        num += ',00'
    }
    else {
        if (num[num.length-3] !== ',' && num !== '' && num !== '0' && !withoutdec) {
            num += '0'
        }
        else if (num === '' || num === '0' && !withoutdec) {
            num = '0,00'
        }
    }
    return num
}


export function feedRei(id_budget, annee, hypothese, type_organisme, ident) {
    var params = {}
    if (type_organisme === 'COM') {
        params = {
            'REI': true,
            'type': 'COM',
            'insee': ident,
            'annee': annee
        }
    }
    else if (type_organisme == 'GFP') {
        params = {
            'REI': true,
            'type': 'GFP',
            'siren': ident,
            'annee': annee
        }
    }
    axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
    .then(async (result) => {
        if (result.data['status'] != 0)
        {
            var tab = {
                'REIBASEFB': 0,
                'REIBASETH': 0,
                'REIBASETHLV': 0,
                'REIBASEFNB': 0,
                'REIBASECFE': 0,
                'REIBASECFEZAE': 0,
                'REIBASECFEEOL': 0,
                'REIBASETASCOM': 0,
                'REIBASETEOMTP': 0,
                'REIBASETEOMTRA': 0,
                'REIBASETEOMTRB': 0,
                'REIBASETEOMTRC': 0,
                'REIBASETEOMTRD': 0,
                'REIBASETEOMTRE': 0,
                'REITXFB': 0,
                'REITXTH': 0,
                'REITXTHRSMAJO': 0,
                'REITXFNB': 0,
                'REITXFNB': 0,
                'REITXCFE': 0,
                'REITXCFEZAE': 0,
                'REITXCFEEOL': 0,
                'REITXTASCOM': 0,
                'REITXTEOMTP': 0,
                'REITXTEOMTRA': 0,
                'REITXTEOMTRB': 0,
                'REITXTEOMTRC': 0,
                'REITXTEOMTRD': 0,
                'REITXTEOMTRE': 0,
                'REIPRDCVAE': 0,
                'REIPRDTAFNB': 0,
                'REIPRDTIEOM': 0,
                'REIPRDIFER': 0,
                'REIPRDIFERZAE': 0,
                'REIPRDIFEREOL': 0,
                'REIPRDCVAE': 0,
                'REIPRDTASCOM': 0,
                'REIPRDTIEOM': 0,
                'REIPRDFB': 0,
                'REIPRDCOCO': 0,
                'REIPRDTH': 0,
                'REIPRDFNB': 0,
                'REIPRDCFE': 0,
                'REIPRDCFEZAE': 0,
                'REIPRDCFEEOL': 0,
                'REIPRDTHLV': 0
            }

            // Partie Commune
            if (type_organisme == 'COM') {
                for(const line in result.data)
                {
                    var ligne = result.data[line].split('--')
                    ligne.forEach((element) => {
                        var col = element.split(':')
                        var valeur = 0
                        if (parseFloat(col[1].replace(',', '.'))) {
                            valeur = parseFloat(col[1].replace(',', '.'))
                        }

                        /* Bases */
                        if (col[0] === 'E11') {
                            tab.REIBASEFB += valeur
                        }
                        if (annee < 2021 && col[0] === 'H11') {
                            tab.REIBASETH += valeur
                        }
                        else if (annee >= 2021 && col[0] === 'H11THS') {
                            tab.REIBASETH += valeur
                        }
                        if (col[0] === 'H11LV') {
                            tab.REIBASETHLV += valeur
                        }
                        if (col[0] === 'B11') {
                            tab.REIBASEFNB += valeur
                        }
                        if (col[0] === 'P11') { 
                            tab.REIBASECFE += valeur
                        }
    
                        /* Taux */
                        if (col[0] === 'E12') {
                            tab.REITXFB = valeur
                        }
                        if (col[0] === 'H12') {
                            tab.REITXTH = valeur
                        }
                        if (col[0] === 'TXMAJOTHRS') {
                            tab.REITXTHRSMAJO = valeur
                        }
                        if (col[0] === 'B12') {
                            tab.REITXFNB = valeur
                        }
                        if (col[0] === 'P12') {
                            tab.REITXCFE = valeur
                        }
                        if (col[0] === 'TASCOMCOEF') {
                            tab.REITXTASCOM = valeur
                        }

                        /* Produits */
                        if (col[0] === 'COCO VERS' || col[0] === 'COCO PRELEV') {
                            tab.REIPRDCOCO += valeur
                        }
                        if (col[0] === 'MTMAJOTHRS') {
                            tab.REIPRDTHRSMAJO += valeur
                        }
                        if (col[0] === 'B13TAFNB') {
                            tab.REIPRDTAFNB += valeur
                        }
                        if (col[0] === 'TIEOMC') {
                            tab.REIPRDTIEOM += valeur
                        }
                    });
                }

                /* Produits calculés */
                tab.REIPRDFB = Math.round(tab.REIBASEFB * tab.REITXFB) / 100
                tab.REIPRDFNB = Math.round(tab.REIBASEFNB * tab.REITXFNB) / 100
                tab.REIPRDTH = Math.round(tab.REIBASETH * tab.REITXTH) / 100
                tab.REIPRDTHLV = Math.round(tab.REIBASETHLV * tab.REITXTH) / 100
                tab.REIPRDCFE = Math.round(tab.REIBASECFE * tab.REITXCFE) / 100
                tab.REIPRDCFEZAE = Math.round(tab.REIBASECFEZAE * tab.REITXCFEZAE) / 100
                tab.REIPRDCFEEOL = Math.round(tab.REIBASECFEEOL * tab.REITXCFEEOL) / 100
            }
            else if (type_organisme == 'GFP')
            {
                for(const line in result.data)
                {
                    var ligne = result.data[line].split('--')
                    var OPTEPCI = ligne[1].split(':')[1]
                    var F71 = ligne[29].split(':')[1]
                    ligne.forEach((element) => {
                        var col = element.split(':')
                        var valeur = 0
                        if (parseFloat(col[1].replace(',', '.'))) {
                            valeur = parseFloat(col[1].replace(',', '.'))
                        }
    
                        /* Bases */
                        // Check pour la somme de zero pour les valeurs
                        if (col[0] === 'E31') {
                            tab.REIBASEFB += valeur
                        }
                        if (annee < 2021 && col[0] === 'H31') {
                            tab.REIBASETH += valeur
                        }
                        else if (annee >= 2021 && col[0] === 'H31THS') {
                            tab.REIBASETH += valeur
                        }
                        if (col[0] === 'H31LV') {
                            tab.REIBASETHLV += valeur
                        }
                        if (col[0] === 'B31') {
                            tab.REIBASEFNB += valeur
                        }
                        if (col[0] === 'P31_1') {
                            if (OPTEPCI === 'FPA' || OPTEPCI === 'FPZ') {
                                tab.REIBASECFE += valeur
                            }
                        }
                        if (col[0] === 'P31_2') {
                            if (OPTEPCI === 'FPU') {
                                tab.REIBASECFE += valeur
                            }
                            if (OPTEPCI === 'FPZ') {
                                tab.REIBASECFEZAE += valeur
                            }
                        }
                        if (col[0] === 'P31_3') {
                            tab.REIBASECFEEOL += valeur
                        }
                        if (col[0] === 'F21' && F71 === '3') {
                            tab.REIBASETEOMTP += valeur
                        }
                        if (col[0] === 'F31' && F71 === '3') {
                            tab.REIBASETEOMTRA += valeur
                        }
                        if (col[0] === 'F41' && F71 === '3') {
                            tab.REIBASETEOMTRB += valeur
                        }
                        if (col[0] === 'F51' && F71 === '3') {
                            tab.REIBASETEOMTRC += valeur
                        }
                        if ((col[0] === 'F61' || col[0] === 'F81') && F71 === '3') {
                            tab.REIBASETEOMTRD += valeur
                        }
    
                        /* Taux */
                        if (col[0] === 'E32VOTE') {
                            tab.REITXFB = valeur
                        }
                        if (col[0] === 'H32VOTE') {
                            tab.REITXTH = valeur
                        }
                        if (col[0] === 'B32VOTE') {
                            tab.REITXFNB = valeur
                        }
                        if (col[0] === 'P32_1vote') {
                            if (OPTEPCI === 'FPA' || OPTEPCI === 'FPZ') {
                                tab.REITXCFE = valeur
                            }
                        }
                        if (col[0] === 'P32_2vote') {
                            if (OPTEPCI === 'FPU') {
                                tab.REITXCFE = valeur
                            }
                            else if (OPTEPCI === 'FPZ') {
                                tab.REITXCFEZAE = valeur
                            }
                        }
                        if (col[0] === 'P32_3vote') {
                            tab.REITXCFEEOL = valeur
                        }
                        if (col[0] === 'F22' && F71 === '3') {
                            tab.REITXTEOMTP = valeur
                        }
                        if (col[0] === 'F32' && F71 === '3') {
                            tab.REITXTEOMTRA = valeur
                        }
                        if (col[0] === 'F42' && F71 === '3') {
                            tab.REITXTEOMTRB = valeur
                        }
                        if (col[0] === 'F52' && F71 === '3') {
                            tab.REITXTEOMTRC = valeur
                        }
                        if (col[0] === 'F62' && F71 === '3') {
                            tab.REITXTEOMTRD = valeur
                        }
                        if (col[0] === 'F82' && F71 === '3') {
                            tab.REITXTEOMTRE = valeur
                        }
                        if (col[0] === 'TASCOMCOEF') {
                            tab.REITXTASCOM = valeur
                        }

                        /* Produits */
                        if (col[0] === 'B33TAFNB') {
                            tab.REIPRDTAFNB += valeur
                        }
                        if (col[0] === 'TIEOMG') {
                            tab.REIPRDTIEOM += valeur
                        }
                    });
                }

                /* Produits calculés */
                tab.REIPRDFB = Math.round(tab.REIBASEFB * tab.REITXFB) / 100
                tab.REIPRDFNB = Math.round(tab.REIBASEFNB * tab.REITXFNB) / 100
                tab.REIPRDTH = Math.round(tab.REIBASETH * tab.REITXTH) / 100
                tab.REIPRDTHLV = Math.round(tab.REIBASETHLV * tab.REITXTH) / 100
                tab.REIPRDCFE = Math.round(tab.REIBASECFE * tab.REITXCFE) / 100
                tab.REIPRDCFEZAE = Math.round(tab.REIBASECFEZAE * tab.REITXCFEZAE) / 100
                tab.REIPRDCFEEOL = Math.round(tab.REIBASECFEEOL * tab.REITXCFEEOL) / 100
                
            }

            const params = {
                'feedRei': true,
                'tab': tab,
                'annee': annee,
                'id_budget': id_budget,
                'hypothese': hypothese
            }
            axios.post('https://app-slfinance.fr/api/datas_rei.php', qs.stringify(params))
            .then((res) => {
                return res.data['status']
            })
        }
        else {
            return result.data['status']
        }
    })
}

export async function checkUser(token) {
    var ret = false
    const params = {
        'session_token': token
    }
    await axios.post('https://app-slfinance.fr/api/connexion.php', qs.stringify(params))
    .then(async (res) => {
        if (res.data !== false) {
            ret = res.data
        }
    })
    return ret
}

export function exportGrilleSaisieCsv(objet, annees, filename, organisme, budget) {
    var tmp = []
    // Création de l'entête du csv
    tmp[0] = '"Comptes"'
    annees.forEach(annee => {
        tmp[0] += ',"'+annee+'"'
    });
    //Création des lignes du csv
    var i = 1
    objet.forEach(line => {
        if (filename.includes('FISCALITE')) {
            tmp[i] = '"'+line[0][1]+'"'
        }
        else {
            tmp[i] = '"'+line[0][1]+'-'+line[0][2]+'"'
        }
        line.forEach((annee, j) => {
            if (j >= 1) {
                tmp[i] += ',"'+((Math.round(annee[1][0] * 100) / 100).toString().replace('.', ','))+'"'
            }
        });
        i++;
    });

    const params = {
        "exportGrilleSaisieCsv": true,
        "filename": filename,
        "objet": tmp,
        "organisme": organisme.replace(' ', '_').toLowerCase(),
        "budget": budget.replace(' ', '_').toLowerCase()
    }
    axios.post('https://app-slfinance.fr/api/import_export.php', qs.stringify(params))
    .then((ret) => {
        // window.open('https://'+ret.data)
        console.log(ret.data)
    })
    .catch((err) => {
        console.log(err)
    })
}



export async function getyears(id_budget, retroprosp) {

    // Recuperation des annees de retro et prosp
    let retro_an_deb = ''
    let retro_an_fin = ''
    let prosp_an_fin = ''
    await axios.get('https://app-slfinance.fr/api/config_budget.php?id_budget='+id_budget)
    .then((result) => {
        result.data.forEach(element => {
            if (element[2] === 'retro_an_deb') {
                retro_an_deb = parseInt(element[3])
            }
            if (element[2] === 'retro_an_fin') {
                retro_an_fin = parseInt(element[3])
            }
            if (element[2] === 'prosp_an_fin') {
                prosp_an_fin = parseInt(element[3])
            }
        });
    }).catch((err) => {console.log(err)})

    if (retro_an_deb === '' || retro_an_fin === '' || prosp_an_fin === '') {
        await axios.get('https://app-slfinance.fr/api/application.php?access_token=StrategiesLocales')
        .then(async function (res) {
            if (res.data.prosp_an_deb && res.data.prosp_an_fin) {
                if (retro_an_deb === '') {
                    retro_an_deb = parseInt(res.data.retro_an_deb)
                }
                if (retro_an_fin === '') {
                    retro_an_fin = parseInt(res.data.retro_an_fin)
                }
                if (prosp_an_fin === '') {
                    prosp_an_fin = parseInt(res.data.prosp_an_fin)
                }
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }
    var liste_an = []
    var i = 0
    if (retroprosp === 'R') {
        while (retro_an_deb <= retro_an_fin)
        {
            liste_an[i] = parseInt(retro_an_deb)
            retro_an_deb++;
            i++;
        }
    }
    else {
        while (retro_an_fin <= prosp_an_fin)
        {
            liste_an[i] = parseInt(retro_an_fin)
            retro_an_fin++;
            i++;
        }
    }
    return liste_an;
}


export async function rescueMonth(month) {
    if (month == 'janvier') {
        month = 'Janvier'
    }
    if (month == 'fevrier') {
        month = 'Février'
    }
    if (month == 'mars') {
        month = 'Mars'
    }
    if (month == 'avril') {
        month = 'Avril'
    }
    if (month == 'mai') {
        month = 'Mai'
    }
    if (month == 'juin') {
        month = 'Juin'
    }
    if (month == 'juillet') {
        month = 'Juillet'
    }
    if (month == 'aout') {
        month = 'Août'
    }
    if (month == 'septembre') {
        month = 'Septembre'
    }
    if (month == 'octobre') {
        month = 'Octobre'
    }
    if (month == 'novembre') {
        month = 'Novembre'
    }
    if (month == 'decembre') {
        month = 'Décembre'
    }
    return month;
}


export async function siphonnage(vars)
{
    var count_sc = [];
    var index = 0;
    var tmp = [];
    var start = parseInt(vars.debut)-1;
    var end = parseInt(vars.fin);
    var tmp_end = end;
    var datas_tmp = [];
    var nomen = '';

    // Boucle qui va recuperer les infos des budgets par année.
    while(start <= end)
    {
        count_sc[end] = 0;

        /******* Protocole si c'est une Commune ********/
        if (vars.ComGfp === "COM")
        {
            // Recuperation de la liste des budgets (siret, nom, budget annexe)
            await axios.get('https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-communes-en-'+end+'&q=&rows=-1&facet=ident&facet=lbudg&facet=cbudg&refine.ident='+vars.siret)
            .then(async function (result)
            {
                await result.data.records.forEach(record =>
                {
                    // Creation de la liste des datas_brut
                    if ( record.fields.ident == vars.siret)
                    {
                        if (nomen === '' || !nomen.includes(record.fields.nomen.replace('A', ''))) {
                            nomen = nomen + '' + record.fields.nomen.replace('A', '') + ';';
                        }
                        if (tmp[record.fields.exer] === undefined)
                        {
                            tmp[record.fields.exer] = [];
                            tmp[record.fields.exer].rd001 = 0;
                            tmp[record.fields.exer].rd001_tmp = 0;
                        }
                        
                        record.fields.compte = record.fields.compte.toString();
                        record.fields.onbcre = Math.round(parseFloat(record.fields.onbcre) * 100) / 100;
                        record.fields.onbdeb = Math.round(parseFloat(record.fields.onbdeb) * 100) / 100;
                        record.fields.sc = Math.round(parseFloat(record.fields.sc) * 100) / 100;
                        record.fields.sd = Math.round(parseFloat(record.fields.sd) * 100) / 100;
                        record.fields.obnetcre = Math.round(parseFloat(record.fields.obnetcre) * 100) / 100;
                        record.fields.obnetdeb = Math.round(parseFloat(record.fields.obnetdeb) * 100) / 100;
                        record.fields.oobcre = Math.round(parseFloat(record.fields.oobcre) * 100) / 100;
                        record.fields.oobdeb = Math.round(parseFloat(record.fields.oobdeb) * 100) / 100;

                        if (record.fields.compte === '110') {
                            tmp[record.fields.exer].compte110 = record.fields.sc;
                            tmp[record.fields.exer].rd001_tmp -= (record.fields.sc);
                        }
                        if (record.fields.compte === '119') {
                            tmp[record.fields.exer].compte119 = record.fields.sd;
                            tmp[record.fields.exer].rd001_tmp += (record.fields.sd);
                        }

                        if (record.fields.compte === '1069'
                        || (record.fields.compte.startsWith('15') && record.fields.compte.endsWith('1'))
                        || (record.fields.compte.startsWith('1688'))
                        || (record.fields.compte.startsWith('29') && record.fields.compte.endsWith('1'))
                        || record.fields.compte.startsWith('3')
                        || record.fields.compte.startsWith('4')
                        || record.fields.compte.startsWith('5'))
                        {
                            if (!record.fields.compte.startsWith('31')
                            && !record.fields.compte.startsWith('33')
                            && !record.fields.compte.startsWith('35')
                            && !record.fields.compte.startsWith('454')
                            && !record.fields.compte.startsWith('455')
                            && !record.fields.compte.startsWith('456')
                            && !record.fields.compte.startsWith('457')
                            && !record.fields.compte.startsWith('458')
                            && !record.fields.compte.startsWith('481'))
                            {
                                if ((record.fields.compte.startsWith('39')
                                || record.fields.compte.startsWith('49')
                                || record.fields.compte.startsWith('59'))
                                && record.fields.compte.endsWith('2')) {}
                                else {
                                    tmp[record.fields.exer].rd001 += (record.fields.sd - record.fields.sc);
                                }
                            }
                        }
                        
                        if (record.fields.compte.startsWith('1')
                        || record.fields.compte.startsWith('2')
                        || record.fields.compte.startsWith('31')
                        || record.fields.compte.startsWith('33')
                        || record.fields.compte.startsWith('35'))
                        {
                            if (record.fields.compte !== '1069' && !record.fields.compte.startsWith('15') && !record.fields.compte.startsWith('1688')) {
                                tmp[record.fields.exer].rd001_tmp += (record.fields.onbcre - record.fields.onbdeb);
                            }
                        }

                        if (((record.fields.obnetcre - record.fields.oobcre) != 0 || (record.fields.obnetdeb - record.fields.oobdeb) != 0))
                        {
                            datas_tmp[index] = []
                            datas_tmp[index]['siret'] = record.fields.ident;
                            datas_tmp[index]['nomen'] = record.fields.nomen.replace('A', '');
                            datas_tmp[index]['annee'] = record.fields.exer;
                            datas_tmp[index]['compte'] = record.fields.compte;
                            if ((record.fields.obnetcre - record.fields.oobcre) != 0) {
                                datas_tmp[index]['recette'] = Math.round((record.fields.obnetcre - record.fields.oobcre) * 100) / 100;
                            }
                            if ((record.fields.obnetdeb - record.fields.oobdeb) != 0) {
                                datas_tmp[index]['depense'] = Math.round((record.fields.obnetdeb - record.fields.oobdeb) * 100) / 100;
                            }
                            datas_tmp[index]['hypothese'] = 'R';

                            if (record.fields.compte === '1641' || record.fields.compte === '1643' || record.fields.compte.startsWith('163')) {
                                if (!count_sc[end]) {
                                    count_sc[end] = 0;
                                }
                                count_sc[end] += record.fields.sc;
                            }
                            index++
                        }
                    }
                });
            })
            .catch((err) => {
                console.log(err)
            })
        }
        /***********************************************/


        /******* Protocole si c'est un EPCI/GFP ********/
        if (vars.ComGfp === "GFP" || vars.ComGfp === "SYN")
        {
            if (vars.ComGfp === "GFP" && end > 2018)
            {
                // Requette pour recuperer les dernieres années sur data.economie.gouv pour un GFP
                var url = ""
                if (vars.ComGfp === "GFP") {
                    url = 'https://data.economie.gouv.fr/api/records/1.0/search/?dataset=balances-comptables-des-groupements-a-fiscalite-propre-depuis-2010&q=&rows=-1&facet=ident&facet=lbudg&facet=nomen&facet=siren&facet=compte&refine.ident='+vars.siret+'&refine.exer='+end
                }
                await axios.get(url)
                .then(async function (result) {
                    await result.data.records.forEach(record =>
                    {
                        // Creation de la liste des datas_brut
                        if (record.fields.ident === vars.siret)
                        {
                            if (nomen === '' || !nomen.includes(record.fields.nomen.replace('A', ''))) {
                                nomen = nomen + '' + record.fields.nomen.replace('A', '') + ';';
                            }
                            if (tmp[record.fields.exer] === undefined)
                            {
                                tmp[record.fields.exer] = [];
                                tmp[record.fields.exer].rd001 = 0;
                                tmp[record.fields.exer].rd001_tmp = 0;
                            }
                            
                            record.fields.compte = record.fields.compte.toString();
                            record.fields.onbcre = Math.round(parseFloat(record.fields.onbcre) * 100) / 100;
                            record.fields.onbdeb = Math.round(parseFloat(record.fields.onbdeb) * 100) / 100;
                            record.fields.sc = Math.round(parseFloat(record.fields.sc) * 100) / 100;
                            record.fields.sd = Math.round(parseFloat(record.fields.sd) * 100) / 100;
                            record.fields.obnetcre = Math.round(parseFloat(record.fields.obnetcre) * 100) / 100;
                            record.fields.obnetdeb = Math.round(parseFloat(record.fields.obnetdeb) * 100) / 100;
                            record.fields.oobcre = Math.round(parseFloat(record.fields.oobcre) * 100) / 100;
                            record.fields.oobdeb = Math.round(parseFloat(record.fields.oobdeb) * 100) / 100;

                            if (record.fields.compte === '110') {
                                tmp[record.fields.exer].compte110 = record.fields.sc;
                            }
                            if (record.fields.compte === '119') {
                                tmp[record.fields.exer].compte119 = record.fields.sd;
                            }

                            if (record.fields.compte === '1069'
                            || (record.fields.compte.startsWith('15') && record.fields.compte.endsWith('1'))
                            || (record.fields.compte.startsWith('1688'))
                            || (record.fields.compte.startsWith('29') && record.fields.compte.endsWith('1'))
                            || record.fields.compte.startsWith('3')
                            || record.fields.compte.startsWith('4')
                            || record.fields.compte.startsWith('5'))
                            {
                                if (!record.fields.compte.startsWith('31')
                                && !record.fields.compte.startsWith('33')
                                && !record.fields.compte.startsWith('35')
                                && !record.fields.compte.startsWith('454')
                                && !record.fields.compte.startsWith('455')
                                && !record.fields.compte.startsWith('456')
                                && !record.fields.compte.startsWith('457')
                                && !record.fields.compte.startsWith('458')
                                && !record.fields.compte.startsWith('481'))
                                {
                                    if ((record.fields.compte.startsWith('39')
                                    || record.fields.compte.startsWith('49')
                                    || record.fields.compte.startsWith('59'))
                                    && record.fields.compte.endsWith('2')) {}
                                    else {
                                        tmp[record.fields.exer].rd001 += (record.fields.sd - record.fields.sc);
                                    }
                                }
                            }
                            
                            if (record.fields.compte.startsWith('1')
                            || record.fields.compte.startsWith('2')
                            || record.fields.compte.startsWith('31')
                            || record.fields.compte.startsWith('33')
                            || record.fields.compte.startsWith('35'))
                            {
                                if (record.fields.compte !== '1069' && !record.fields.compte.startsWith('15') && !record.fields.compte.startsWith('1688')) {
                                    tmp[record.fields.exer].rd001_tmp += (record.fields.onbcre - record.fields.onbdeb);
                                }
                            }
                            if (record.fields.compte === "110") {
                                tmp[record.fields.exer].rd001_tmp -= (record.fields.sc);
                            }
                            else if (record.fields.compte === "119") {
                                tmp[record.fields.exer].rd001_tmp += (record.fields.sd);
                            }

                            if (((record.fields.obnetcre - record.fields.oobcre) != 0 || (record.fields.obnetdeb - record.fields.oobdeb) != 0))
                            {
                                record.fields.nomen = record.fields.nomen.replace('A', '');
                                datas_tmp[index] = []
                                datas_tmp[index]['siret'] = record.fields.ident
                                datas_tmp[index]['nomen'] = record.fields.nomen
                                datas_tmp[index]['annee'] = record.fields.exer
                                datas_tmp[index]['compte'] = record.fields.compte
                                if ((record.fields.obnetcre - record.fields.oobcre) != 0) {
                                    datas_tmp[index]['recette'] = Math.round((record.fields.obnetcre - record.fields.oobcre) * 100) / 100
                                }
                                if ((record.fields.obnetdeb - record.fields.oobdeb) != 0) {
                                    datas_tmp[index]['depense'] = Math.round((record.fields.obnetdeb - record.fields.oobdeb) * 100) / 100
                                }
                                datas_tmp[index]['hypothese'] = 'R';

                                if (record.fields.compte === '1641' || record.fields.compte === '1643' || record.fields.compte.startsWith('163')) {
                                    if (!count_sc[end]) {
                                        count_sc[end] = 0;
                                    }
                                    count_sc[end] += record.fields.sc;
                                }
                                index++
                            }
                        }
                    });
                })
                .catch((err) => {
                    console.log(err)
                })
            }
            else if ((vars.ComGfp === "GFP" && end >= 2013 && end <= 2018) || vars.ComGfp == "SYN")
            {
                /* Requette pour lires les infos depuis les fichiers */
                var params = {}
                if (vars.ComGfp === "GFP") {
                    params = {
                        'annee': end,
                        'siret': vars.siret,
                        'GFP': true
                    }
                }
                else if (vars.ComGfp === "SYN") {
                    params = {
                        'annee': end,
                        'siret': vars.siret,
                        'SYN': true
                    }
                }
                
                await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
                .then(async (result) => {
                    if (result.data.status !== 0)
                    {
                        await result.data.forEach(async record =>
                        {
                            // Creation de la liste des datas_brut
                            if (record.ident === vars.siret)
                            {
                                if (nomen === '' || !nomen.includes(record.nomen.replace('A', ''))) {
                                    nomen = nomen + '' + record.nomen.replace('A', '') + ';';
                                }
                                if (tmp[record.exer] === undefined) {
                                    tmp[record.exer] = [];
                                    tmp[record.exer].rd001 = 0;
                                    tmp[record.exer].rd001_tmp = 0;
                                }
                                
                                record.compte = record.compte.toString();
                                record.onbcre = Math.round(parseFloat(record.onbcre.replace(",", ".")) * 100) / 100;
                                record.onbdeb = Math.round(parseFloat(record.onbdeb.replace(",", ".")) * 100) / 100;
                                record.sc = Math.round(parseFloat(record.sc.replace(",", ".")) * 100) / 100;
                                record.sd = Math.round(parseFloat(record.sd.replace(",", ".")) * 100) / 100;
                                record.obnetcre = Math.round(parseFloat(record.obnetcre.replace(",", ".")) * 100) / 100;
                                record.obnetdeb = Math.round(parseFloat(record.obnetdeb.replace(",", ".")) * 100) / 100;
                                record.oobcre = Math.round(parseFloat(record.oobcre.replace(",", ".")) * 100) / 100;
                                record.oobdeb = Math.round(parseFloat(record.oobdeb.replace(",", ".")) * 100) / 100;

                                if (record.compte === '110') {
                                    tmp[record.exer].compte110 = record.sc;
                                }
                                if (record.compte === '119') {
                                    tmp[record.exer].compte119 = record.sd;
                                }

                                if (record.compte === '1069'
                                || (record.compte.startsWith('15') && record.compte.endsWith('1'))
                                || (record.compte.startsWith('1688'))
                                || (record.compte.startsWith('29') && record.compte.endsWith('1'))
                                || record.compte.startsWith('3')
                                || record.compte.startsWith('4')
                                || record.compte.startsWith('5'))
                                {
                                    if (!record.compte.startsWith('31')
                                    && !record.compte.startsWith('33')
                                    && !record.compte.startsWith('35')
                                    && !record.compte.startsWith('454')
                                    && !record.compte.startsWith('455')
                                    && !record.compte.startsWith('456')
                                    && !record.compte.startsWith('457')
                                    && !record.compte.startsWith('458')
                                    && !record.compte.startsWith('481'))
                                    {
                                        if ((record.compte.startsWith('39')
                                        || record.compte.startsWith('49')
                                        || record.compte.startsWith('59'))
                                        && record.compte.endsWith('2')) {}
                                        else {
                                            tmp[record.exer].rd001 += (record.sd - record.sc);
                                        }
                                    }
                                }
                                
                                if (record.compte.startsWith('1')
                                || record.compte.startsWith('2')
                                || record.compte.startsWith('31')
                                || record.compte.startsWith('33')
                                || record.compte.startsWith('35'))
                                {
                                    if (record.compte !== '1069' && !record.compte.startsWith('15') && !record.compte.startsWith('1688')) {
                                        tmp[record.exer].rd001_tmp += (record.onbcre - record.onbdeb);
                                    }
                                }
                                if (record.compte === "110") {
                                    tmp[record.exer].rd001_tmp -= (record.sc);
                                }
                                else if (record.compte === "119") {
                                    tmp[record.exer].rd001_tmp += (record.sd);
                                }

                                if (((record.obnetcre - record.oobcre) != 0 || (record.obnetdeb - record.oobdeb) != 0))
                                {
                                    if (record.exer >= 2013)
                                    {
                                        record.nomen = record.nomen.replace('A', '');
                                        datas_tmp[index] = [];
                                        datas_tmp[index]['siret'] = record.ident;
                                        datas_tmp[index]['nomen'] = record.nomen;
                                        datas_tmp[index]['annee'] = record.exer;
                                        datas_tmp[index]['compte'] = record.compte;
                                        if ((record.obnetcre - record.oobcre) != 0) {
                                            datas_tmp[index]['recette'] = Math.round((record.obnetcre - record.oobcre) * 100) / 100;
                                        }
                                        if ((record.obnetdeb - record.oobdeb) != 0) {
                                            datas_tmp[index]['depense'] = Math.round((record.obnetdeb - record.oobdeb) * 100) / 100;
                                        }
                                        datas_tmp[index]['hypothese'] = 'R';
                                        
                                        if (record.compte === '1641' || record.compte === '1643' || record.compte.startsWith('163')) {
                                            if (!count_sc[end]) {
                                                count_sc[end] = 0;
                                            }
                                            count_sc[end] += record.sc;
                                        }
                                        index++
                                    }
                                }
                            }
                        });
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            }
        }
        /***********************************************/


        /* Creation de la banque */
        var dette = count_sc[end];
        var nom_banque = 'Dette Financière';
        var rang = 1;
        await axios.post('https://app-slfinance.fr/api/banques.php', qs.stringify({'add': true, 'id_budget': vars.id_budget, 'nom': nom_banque, 'rang': rang, 'dette': dette, 'annee': end}))
        /*************************/

        if (tmp[(end)+''] !== undefined)
        {
            /* Stockage et creation du RD002 */
            datas_tmp[index] = [];
            datas_tmp[index]['siret'] = vars.siret;
            datas_tmp[index]['annee'] = (end)+'';
            datas_tmp[index]['matricule'] = '2014M57R002';
            datas_tmp[index]['hypothese'] = 'R';
            datas_tmp[index]['recette'] = parseFloat(tmp[(end)+'']['compte110'])?parseFloat(tmp[(end)+'']['compte110']):0;
            index++;

            datas_tmp[index] = [];
            datas_tmp[index]['siret'] = vars.siret;
            datas_tmp[index]['annee'] = (end)+'';
            datas_tmp[index]['matricule'] = '2014M57D002';
            datas_tmp[index]['hypothese'] = 'R';
            datas_tmp[index]['depense'] = parseFloat(tmp[(end)+'']['compte119'])?parseFloat(tmp[(end)+'']['compte119']):0;
            index++;
        }
        
        /* Creations des donnees brutes */
        if (vars.ComGfp === "COM" && vars.code_geo !== '') {
            await feedRei(vars.id_budget, end, 'R', vars.ComGfp, vars.code_geo);
        }
        if (vars.ComGfp === "GFP") {
            await feedRei(vars.id_budget, end, 'R', vars.ComGfp, vars.siren)
        }

        end--;
    }

    while (start <= tmp_end)
        {
            /* Stockage et creation du RD001 */
            if (tmp[(start-1)+''] != undefined && tmp[(start)+''] != undefined) {
                var rd001 = tmp[start-1+'']['rd001'] + tmp[(start)+'']['rd001_tmp'];
                rd001 = Math.round(rd001 * 100) / 100;
                if (rd001 != 0)
                {
                    datas_tmp[index] = [];
                    datas_tmp[index]['siret'] = vars.siret;
                    datas_tmp[index]['annee'] = (start)+'';
                    if (rd001 > 0) {
                        datas_tmp[index]['matricule'] = '2014M57R001';
                        datas_tmp[index]['recette'] = rd001;
                    }
                    else if (rd001 < 0) {
                        datas_tmp[index]['matricule'] = '2014M57D001';
                        datas_tmp[index]['depense'] = rd001 * -1;
                    }
                    datas_tmp[index]['hypothese'] = 'R';
                    index++;
                }
            }
        start++;
    }
    

    /* Creations des donnees brutes */
    if (datas_tmp.length != 0)
    {
        var ret = '';
        var tmpi = 0
        var tab_tmp = {}
        while(tmpi <= datas_tmp.length / 100)
        {
            tab_tmp[tmpi] = datas_tmp.slice(0 + (tmpi * 100), 100 + (tmpi * 100))
            const params = {
                'feed_data_brut': true,
                'id_organisme': vars.id_organisme,
                'list_datas': tab_tmp[tmpi]
            };
            
            /* Insertion des datas_brut */
            await axios.post('https://app-slfinance.fr/api/datas_brut.php', qs.stringify(params))
            .then(async function (result) {
                if (result.data['status'] === 1) {
                    NotificationManager.success(result.data['status_message'], '', 2000);
                }
                else {
                    NotificationManager.warning(result.data['status_message'], '', 10000);
                }
            })
            tmpi++;
        }
        if (ret === '') {
            ret = 'Insertion des données validée';
        }
    }

    /* Vérification si la table config_budget est bien remplie pour ce budget. Si ce n'est pas le cas alors crée le champ nomen->valeur */
    await axios.post("https://app-slfinance.fr/api/config_budget.php", qs.stringify({
        'verif_nomen': true,
        'id_budget': vars.id_budget,
        'parametre': 'nomen',
        'valeur': nomen,
    }))
    .catch((err) => {
        console.log(err)
    })
}
import React, { useEffect, useState } from 'react';
import Access from "../../components/Access";
import axios from 'axios';
import qs from 'qs';
import BounceLoader from "react-spinners/BounceLoader";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import AddlineSaisie from './AddlineSaisie';
import { addSpace } from '../../components/Tools';
import { ReactComponent as Add } from '../../Img/icon-add.svg';
import { ReactComponent as Check } from '../../Img/icon-check.svg';
import { ReactComponent as CloseEye } from '../../Img/icon-close-eye.svg';
import { ReactComponent as OpenEye } from '../../Img/icon-open-eye.svg';

const GrilleSaisie = props =>
{
    let recdep = window.location.pathname.split('/')[4];
    const [aff, setAff] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [validButton, setValidButton] = useState(false);
    const [souscomptes, setSouscomptes] = useState(true);
    const heightObject = window.innerHeight/1.9
    
    const greyChapter = '#DBDBDB'
    const blueTx = '#DEF3FF'
    const purple = '#6571FE'
    const blue = '#069BD3'
    const smoothGrey = '#f4f4f4'
    const green = '#59B44A'
    const red = '#E33636'


    useEffect(() => {
        if (props.objet.length != 0) {
            calculObjet(props.objet)
        }
    }, [props.objet])



    async function calculObjet(tmp = props.objet)
    {
        await tmp.forEach(async(line, i) => {
            // Uniquement les lignes qui ne sont pas des chapitres
            if (line[0][3] == 3)
            {
                var emptyLine = 'true'
                // Uniquement si la ligne est vide
                await line.forEach((element, j) => {
                    if (j > 1) {
                        if (element[1][recdep] != 0 && element[1][recdep] != '0' && element[1][recdep] != '' && element[1][recdep] != ' ') {
                            emptyLine = 'false'
                        }
                    }
                });
                if (emptyLine == 'true') {
                    var j = 2
                    while(line[j])
                    {
                        if ((Math.round((tmp[i][j-1][1][recdep] * (1 + (tmp[i][j-1][1]['taux'] / 100))) * 100) / 100) != tmp[i][j][1][recdep]) {
                            tmp[i][j][1][recdep] = Math.round((tmp[i][j-1][1][recdep] * (1 + (tmp[i][j-1][1]['taux'] / 100))) * 100) / 100;
                        }
                        j++;
                    }
                }
            }
        });
        // await sommesCalcul(tmp)
    }


    async function totalreelles(tmp)
    {
        await props.annees.forEach(annee =>
        {
            /* Calcul de la ligne TOTAL RECETTES / DEPENSES REELLES DE FONCTIONNEMENT */
            var total = 0
            tmp.forEach((line) => {
                // line[0][3] == 3 correspond aux lignes qui ne sont pas des chapitres
                if (line[0][3] == 3) {
                    line.forEach((cel) => {
                        if (cel[0] == annee) {
                            var val = 0;
                            if (cel[1][recdep] != '0' && cel[1][recdep] != 0) {
                                if (parseFloat(cel[1][recdep])) {
                                    val = parseFloat(cel[1][recdep]);
                                }
                                total = total + val;
                            }
                        }
                    });
                }
            });

            /* Assignation du total a la ligne TOTAL RECETTES / DEPENSES REELLES DE FONCTIONNEMENT */
            tmp.forEach((line, i) => {
                if (line[0][0].includes('REEL')) {
                    line.forEach((cel, j) => {
                        if (j > 0)
                        {
                            if (cel[0] == annee) {
                                // Assignation du montant
                                if ((Math.round(total*100)/100) != tmp[i][j][1][recdep]) {
                                    tmp[i][j][1][recdep] = Math.round(total*100)/100;
                                }

                                // Calcul et assignation du nouveau taux
                                if (j > 1) {
                                    tmp[i][j-1][1]['taux'] = Math.round(((tmp[i][j][1][recdep] - tmp[i][j-1][1][recdep]) / tmp[i][j-1][1][recdep]) * 100 * 100) / 100
                                }
                            }
                        }
                    });
                }
            });
        });
        await props.setObjet(tmp)
        await setAff(!aff)
    }


    async function sommesCalcul(tmp)
    {
        // Bzero les montants des chapitres
        await tmp.forEach((line, k) => {
            if (line[0][3] != 3) {
                var j = 1
                while (line[j])
                {
                    tmp[k][j][1][recdep] = 0
                    j++;
                }
            }
        });
        
        // Premiere boucle pour calculer les petits Chapitres
        await tmp.forEach(async(line) => {
            // line[0][4] correspond a la colonne calcul
            if (line[0][4] != '')
            {
                await tmp.forEach((l, i) => {
                    if (line[0][4] == l[0][0]) {
                        // Sommes sur les montants
                        var j = 1
                        while(l[j])
                        {
                            if (tmp[i][1][recdep] != '' && line[j][1][recdep] != '')
                            {
                                if (line[j][1][recdep] == null) {
                                    line[j][1][recdep] = 0;
                                }
                                else {
                                    line[j][1][recdep] = parseFloat(line[j][1][recdep]);
                                }

                                var somme = (tmp[i][j][1][recdep] + line[j][1][recdep])

                                if ((Math.round(somme * 100) / 100) != tmp[i][j][1][recdep]) {
                                    tmp[i][j][1][recdep] = Math.round(somme * 100) / 100
                                }
                            }
                            j++;
                        }
                        // Recalcul des taux
                        var j = 2
                        while(l[j])
                        {
                            if (tmp[i][j-1][1]) {
                                if (tmp[i][j-1][1][recdep] == 0) {
                                    tmp[i][j-1][1]['taux'] = '0'
                                }
                                else if (tmp[i][j-1][1][recdep] != 0 && tmp[i][j][1][recdep] != 0) {
                                    tmp[i][j-1][1]['taux'] = (((tmp[i][j][1][recdep] - tmp[i][j-1][1][recdep]) / tmp[i][j-1][1][recdep]) * 100).toString()
                                }
                                else if (tmp[i][j-1][1][recdep] != 0 && tmp[i][j][1][recdep] == 0) {
                                    tmp[i][j-1][1]['taux'] = '-100'
                                }
                            }
                            j++;
                        }
                    }
                });
            }
        });

        // Deuxieme boucle pour calculer les Grands Chapitres (ex: 011, 012, etc..)
        await tmp.forEach(async(line) => {
            // line[0][4] correspond a la colonne calcul
            if (line[0][3] == '2' && line[0][4] != '')
            {
                await tmp.forEach((l, i) => {
                    if (line[0][4] == l[0][0]) {
                        // Sommes sur les montants
                        var j = 1
                        while(l[j])
                        {
                            if (tmp[i][1][recdep] != '' && line[j][1][recdep] != '')
                            {
                                if (line[j][1][recdep] == null) {
                                    line[j][1][recdep] = 0;
                                }
                                else {
                                    line[j][1][recdep] = parseFloat(line[j][1][recdep]);
                                }

                                var somme = (tmp[i][j][1][recdep] + line[j][1][recdep])
                                if ((Math.round(somme * 100) / 100) != tmp[i][j][1][recdep]) {
                                    tmp[i][j][1][recdep] = Math.round(somme * 100) / 100;
                                }
                            }
                            j++;
                        }
                        // Recalcul des taux
                        var j = 2
                        while(l[j])
                        {
                            if (tmp[i][j-1][1]) {
                                if (tmp[i][j-1][1][recdep] == 0) {
                                    tmp[i][j-1][1]['taux'] = '0'
                                }
                                else if (tmp[i][j-1][1][recdep] != 0 && tmp[i][j][1][recdep] != 0) {
                                    tmp[i][j-1][1]['taux'] = (((tmp[i][j][1][recdep] - tmp[i][j-1][1][recdep]) / tmp[i][j-1][1][recdep]) * 100).toString()
                                }
                                else if (tmp[i][j-1][1][recdep] != 0 && tmp[i][j][1][recdep] == 0) {
                                    tmp[i][j-1][1]['taux'] = '-100'
                                }
                            }
                            j++;
                        }
                    }
                });
            }
        });
        await totalreelles(tmp)
    }


    async function updateObjetChapter(matricule, an, montant, taux)
    {
        var tmp = props.objet

        /* Uniquement quand le montant est a 0 */
        if (montant == 0 && taux != 'taux')
        {
            /* Parcour le tableau a la recherche des lignes qui ont pour matricule ou dans sa colonne calcul celui recherché. */
            await tmp.forEach((line, i) => {
                if ((line[0][0] == matricule || line[0][4] == matricule) && line[0][0] != '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'66111')
                {
                    var j = 1
                    while(tmp[i][j])
                    {
                        if (tmp[i][j][0] >= an)
                        {
                            tmp[i][j][1][recdep] = '0'
                            if (tmp[i][j-1][1][recdep] != '0' && tmp[i][j-1][1][recdep] != 0 && tmp[i][j-1][1][recdep] != '') {
                                tmp[i][j-1][1]['taux'] = '-100'
                            }
                            else {
                                tmp[i][j-1][1]['taux'] = '0'
                            }
                        }
                        j++;
                    }
                }
            });
        }
        else {
            if (taux == 'taux')
            {
                // Parcour le tableau a la recherche des lignes qui ont pour matricule ou dans sa colonne calcul celui recherché.
                await tmp.forEach(async(line, i) => {
                    if ((line[0][0] == matricule || line[0][4] == matricule) && line[0][0] != '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'66111')
                    {
                        var j = 1
                        while(tmp[i][j])
                        {
                            if (tmp[i][j][0] == an)
                            {
                                if (montant != tmp[i][j][1]['taux']) {
                                    tmp[i][j][1]['taux'] = montant;
                                }
                            }
                            if (tmp[i][j+1] && tmp[i][j][0] >= an) {
                                if ((Math.round((tmp[i][j][1][recdep] * (1 + (tmp[i][j][1]['taux'] / 100))) * 100) / 100) != tmp[i][j+1][1][recdep]) {
                                    tmp[i][j+1][1][recdep] = Math.round((tmp[i][j][1][recdep] * (1 + (tmp[i][j][1]['taux'] / 100))) * 100) / 100;
                                }
                            }
                            j++;
                        }
                    }
                })
            }
            else
            {
                /* Parcourt le tableau a la recherche des lignes qui ont pour matricule ou dans sa colonne calcul celui recherché. */
                var new_taux = 0
                await tmp.forEach(async(line, i) => {
                    if ((line[0][0] == matricule || line[0][4] == matricule) && line[0][0] != '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'66111')
                    {
                        var j = 1
                        while(tmp[i][j])
                        {
                            if (tmp[i][j][0] == an && line[0][0] == matricule)
                            {
                                if (montant != tmp[i][j][1][recdep]) {
                                    tmp[i][j][1][recdep] = montant;
                                }
                                new_taux = (((montant - tmp[i][j-1][1][recdep]) / tmp[i][j-1][1][recdep]) * 100)
                                tmp[i][j-1][1]['taux'] = new_taux
                            }
                            if (tmp[i][j][0] == an && line[0][4] == matricule)
                            {
                                tmp[i][j-1][1]['taux'] = new_taux
                            }
                            if (tmp[i][j][0] >= an)
                            {
                                if ((Math.round((tmp[i][j-1][1][recdep] * (1 + (tmp[i][j-1][1]['taux'] / 100))) * 100) / 100) != tmp[i][j][1][recdep]) {
                                    tmp[i][j][1][recdep] = Math.round((tmp[i][j-1][1][recdep] * (1 + (tmp[i][j-1][1]['taux'] / 100))) * 100) / 100;
                                }
                            }
                            j++;
                        }
                    }
                })
            }
        }
        await sommesCalcul(tmp)
    }


    async function updateObjetLine(matricule, an, montant, taux)
    {
        var tmp = props.objet
        var i = 0
        while(i < tmp.length)
        {
            if (tmp[i][0][0] == matricule)
            {
                var j = 1
                while(j < tmp[i].length)
                {
                    if (tmp[i][j] && tmp[i][j][0] >= an)
                    {
                        if (taux == 'taux')
                        {
                            if (tmp[i][j][1][recdep] == '' || tmp[i][j][1][recdep] == '0' || tmp[i][j][1][recdep] == 0) {
                                tmp[i][j][1]['taux'] = 0
                                tmp[i][j][1][recdep] = 0
                                tmp[i][j][2] = true
                            }
                            if (tmp[i][j+1] != undefined)
                            {
                                var montant_tmp = (parseFloat(tmp[i][j][1][recdep]) + (parseFloat(tmp[i][j][1][recdep]) * montant / 100))
                                if ((Math.round(montant_tmp * 100) / 100) == 0) {
                                    tmp[i][j+1][1][recdep] = 0;
                                }
                                else {
                                    if ((Math.round(montant_tmp * 100) / 100) != tmp[i][j+1][1][recdep]) {
                                        tmp[i][j+1][1][recdep] = (Math.round(montant_tmp * 100) / 100).toString();
                                    }
                                }
                                tmp[i][j][1]['taux'] = montant
                                montant = tmp[i][j+1][1]['taux']
                            }
                            if (tmp[i][j][1]['taux'] == '-100' || tmp[i][j][1]['taux'] == -100) {
                                if (tmp[i][j+1]) {
                                    await updateObjetLine(tmp[i][0][0], tmp[i][j+1][0], tmp[i][j+1][1][recdep], '')
                                    await sommesCalcul(tmp)
                                    return
                                }
                            }
                        }
                        else
                        {
                            if (montant == ' ' || montant == '0' || montant == '' || montant == 0)
                            {
                                // Calcule du nouveau pourcentage
                                if (tmp[i][j-1][1][recdep] != '') {
                                    tmp[i][j-1][1]['taux'] = -100;
                                }
                                while (tmp[i][j])
                                {
                                    tmp[i][j][1]['taux'] = 0
                                    if (0 != tmp[i][j][1][recdep]) {
                                        tmp[i][j][1][recdep] = 0;
                                    }
                                    j++;
                                }
                                await sommesCalcul(tmp)
                                return
                            }
                            if (montant != tmp[i][j][1][recdep]) {
                                tmp[i][j][1][recdep] = montant;
                            }
                            if (tmp[i][j+1]) {
                                montant = tmp[i][j+1][1][recdep];
                            }
                            // Calcule du nouveau pourcentage
                            if (tmp[i][j-1][1][recdep] != '' && tmp[i][j-1][1][recdep] != 0) {
                                tmp[i][j-1][1]['taux'] = ((tmp[i][j][1][recdep] - tmp[i][j-1][1][recdep]) / tmp[i][j-1][1][recdep]) * 100
                            }
                            await updateObjetLine(tmp[i][0][0], tmp[i][j][0], tmp[i][j][1]['taux'], 'taux')
                            await sommesCalcul(tmp)
                            return;
                        }
                    }
                    j++;
                }
            }
            i++;
        }
        await sommesCalcul(tmp)
    }


    async function validation()
    {
        var ret = true
        var tab_tmp = props.objet;
        var cut_tab = {};
        var tmpi = 0

        while(tmpi <= tab_tmp.length / 10)
        {
            cut_tab[tmpi] = tab_tmp.slice((tmpi * 10), 10 + (tmpi * 10))
            const params = {
                'updateGrilleSaisieProsp': true,
                'fctinv': props.fctinv,
                'recdep': props.recdep,
                'hypothese': props.hypothese,
                'id_budget': props.id_budget,
                'grilleSaisie': cut_tab[tmpi]
            }
            await axios.post("https://app-slfinance.fr/api/datas_brut.php", qs.stringify(params))
            .then((res) => {
                console.log(res.data)
            })
            .catch((err) => {
                ret = false
                console.log(err)
            })
            tmpi++;
        }

        return ret;
    }


	return (
        <div>
            <NotificationContainer />
            <Access needConnection={true} type_user={[0]}>
                {props.objet.length == 0 || validButton?
                    <div style={{flex: 1, margin: 100, display: 'flex'}}>
                        <div style={{flex: 1}}></div>
                        <BounceLoader color={purple} />
                        <div style={{flex: 1}}></div>
                    </div>
                    :
                    <div style={{flex: 1}}>
                        <AddlineSaisie modalVisible={modalVisible} setModalVisible={setModalVisible} annees={props.annees.slice(1)} id_budget={props.id_budget} fctinv={props.fctinv} recdep={props.recdep} hypothese={props.hypothese} />

                        <div style={{display: 'flex'}}>
                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: green, color: 'white', marginLeft: 20, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                                onClick={async () => {
                                    setModalVisible(true)
                                    await validation()
                                    .then(() => {
                                        NotificationManager.success("Grille sauvegardée", '', 700);
                                    })
                                }}
                            >
                                <Add width="12"/>
                                <p style={{marginLeft: 5}}>Nouvelle ligne</p>
                            </button>
                        
                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: purple, color: 'white', marginLeft: 20, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                                onClick={() => {
                                    setSouscomptes(!souscomptes);
                                }}
                            >
                                {!souscomptes?
                                <OpenEye width="12"/>
                                :
                                <CloseEye width="12"/>
                                }
                                <p style={{marginLeft: 5}}>{!souscomptes?'Afficher':'Cacher'} les sous comptes</p>
                            </button>
                            
                            <div style={{flex: 1}}></div>

                            <button
                                className='shadow-md rounded-md'
                                style={{backgroundColor: green, color: 'white', marginRight: 20, fontSize: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft:10, paddingRight: 10}}
                                onClick={async () => {
                                    setValidButton(true)
                                    await setAff(!aff)
                                    await validation()
                                    .then(() => {
                                        NotificationManager.success("Grille sauvegardée", '', 700);
                                        setTimeout(() => window.location = window.location.href, 900);
                                    })
                                }}
                            >
                                <Check width="12"/>
                                <p style={{marginLeft: 5}}>Valider</p>
                            </button>
                        </div>

                        {/* Tableau Grille Saisie */}
                        <div style={{flex: 1}}>
                            <div className='shadow-2xl rounded-md' style={{flex: 1, margin: 20, backgroundColor: 'white'}}>

                                {/* Affichage de la premiere ligne avec les annees */}
                                <div style={{display: 'flex', flex: 1, overflowY: 'scroll', backgroundColor: blue, color: 'white', fontSize: 18, minHeight: 50}}>

                                    <div style={{flex: 2.2, marginLeft: 5, minWidth: 150, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <p style={{fontSize: 18}}>Compte</p>
                                    </div>
                                    
                                    {props.annees.map((annee, j) =>
                                    <div key={j} style={{display: 'flex', flex: 1, fontSize: 18, borderLeft: '1px solid white', textAlign: 'center', minWidth: 50}}>
                            
                                        {j > 0?
                                        <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <p style={{fontSize: 13}}>Evol %</p>
                                        </div>
                                        :null}

                                        <div style={{flex: 1.8, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <p style={{fontSize: 18}}>{annee}</p>
                                        </div>
                                        
                                    </div>)}

                                </div>

                                {/* Affichage du tableau de Grille de Saisie */}
                                <div style={{flex: 1, overflowY: 'scroll', maxHeight: heightObject}}>

                                    {/* Listing des lignes */}
                                    {props.objet.map((ligne, i) =>
                                    souscomptes || (!souscomptes && ligne[0][3] != 3)?
                                    <div
                                    key={i}
                                    style={
                                        ligne[0][3] != 3?
                                        {display: 'flex', flex: 1, borderTop: '1px solid white', backgroundColor: greyChapter}
                                        :
                                        i % 2 != 0?
                                            ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'66111' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'775' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'73111'?
                                                {display: 'flex', flex: 1, border: '1px solid '+red}
                                                :
                                                {display: 'flex', flex: 1}
                                            :
                                            ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'66111' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'775' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'73111'?
                                                {display: 'flex', flex: 1, backgroundColor: smoothGrey, border: '1px solid '+red}
                                                :
                                                {display: 'flex', flex: 1, backgroundColor: smoothGrey}
                                    }>
                                        {/* Affichage de la colone des comptes et noms de compte */}
                                        <div style={{flex: 2.2, fontSize: 14, minWidth: 150}}>
                                            {ligne[0][3] != 3?
                                            <div style={{display: 'flex', marginTop: 4, marginBottom: 4}}>
                                                <p style={{fontWeight: "bold", marginLeft: 5, color: 'red'}}>{ligne[0][1] != ''?ligne[0][1]:'-'}</p>
                                                <p style={{fontWeight: "bold", marginLeft: 5, color: 'black'}}>{ligne[0][2]}</p>
                                            </div>
                                            :
                                            <div style={{display: 'flex', marginTop: 4, marginBottom: 4}}>
                                                <p style={{marginLeft: 20, color: 'red'}}>{ligne[0][1] != ''?ligne[0][1]:'-'}</p>
                                                <p style={{marginLeft: 10, color: 'black'}}>{ligne[0][2]}</p>
                                            </div>}
                                        </div>

                                        {/* Listing des cellules */}
                                        {ligne.map((col, j) =>

                                        // Affichage de toutes les colones sauf la 1ere
                                        j > 1?
                                        <div key={j} style={{flex: 1, display: 'flex', fontSize: 11, borderLeft: '1px solid white', minWidth: 50, minHeight: 40}}>
                                            
                                            {/* input pour les taux */}
                                            <input
                                            disabled={
                                                ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'66111' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'775'
                                                || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'66' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'73111'
                                                || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'77' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'731A'
                                                || ligne[0][0].includes('REEL') || ligne[0][0].includes(props.user.nomen+'011') || ligne[0][0].includes(props.user.nomen+'012')?
                                                true:false
                                            }
                                            type="float"
                                            style={
                                                ligne[0][3] != 3?
                                                {flex: 1, width: 2, textAlign: 'center', backgroundColor: greyChapter}
                                                :
                                                {flex: 1, width: 2, textAlign: 'center', backgroundColor: blueTx}
                                            }
                                            onBlur={async (e) => {
                                                e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                                if (e.target.value != '' && (parseFloat(e.target.value) || e.target.value == '0')) {
                                                    e.target.value = parseFloat(e.target.value)
                                                    if (ligne[0][3] == 3) {
                                                        await updateObjetLine(ligne[0][0], ligne[j-1][0], parseFloat(e.target.value).toString(), 'taux')
                                                    }
                                                    else {
                                                        await updateObjetChapter(ligne[0][0], ligne[j-1][0], parseFloat(e.target.value).toString(), 'taux')
                                                    }
                                                    await setAff(!aff)
                                                }
                                                e.target.value = ''
                                            }}
                                            placeholder={addSpace(Math.round(ligne[j-1][1]['taux'] * 100) / 100)+'%'}
                                            />

                                            {/* input pour les montants */}
                                            
                                            <input
                                            disabled={
                                                ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'66111' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'775'
                                                || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'66' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'73111'
                                                || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'77' || ligne[0][0] == '2014'+(props.user.nomen == 'M14'?'M57':props.user.nomen)+'731A'
                                                || ligne[0][0].includes('REEL') || ligne[0][0].includes(props.user.nomen+'011') || ligne[0][0].includes(props.user.nomen+'012')?
                                                true:false
                                            }
                                            type="float"
                                            style={
                                                ligne[0][3] != 3?
                                                {flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', backgroundColor: greyChapter}
                                                :
                                                i%2==0?
                                                    {flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right', backgroundColor: smoothGrey}
                                                    :
                                                    {flex: 1.8, width: 50, paddingRight: 5, textAlign: 'right'}
                                            }
                                            onBlur={async (e) => {
                                                e.target.value = e.target.value.replace(',', '.').replace(/ /g, "")
                                                if (e.target.value != '' && (parseFloat(e.target.value) || e.target.value == '0')) {
                                                    e.target.value = parseFloat(e.target.value)
                                                    if (ligne[0][3] == 3) {
                                                        await updateObjetLine(ligne[0][0], col[0], e.target.value, '')
                                                    }
                                                    else {
                                                        if (e.target.value == '0') {
                                                            await updateObjetChapter(ligne[0][0], col[0], 0, '')
                                                        }
                                                        else
                                                        {
                                                            await updateObjetChapter(ligne[0][0], col[0], e.target.value, '')
                                                        }
                                                    }
                                                    await setAff(!aff)
                                                }
                                                e.target.value = ''
                                            }}
                                            placeholder={addSpace(Math.round(col[1][recdep] * 100) / 100)}
                                            />
                                        </div>
                                        :
                                        // Affichage de la 1ere colone
                                        j == 1?
                                        <div key={j} style={{flex: 1, display: 'flex', marginLeft: 5, borderLeft: '1px solid white', minWidth: 50, alignItems: 'center', justifyContent: 'right'}}>
                                            <p style={{fontSize: 12, color: 'grey', marginRight: 5}}>{addSpace(Math.round(col[1][recdep] * 100) / 100)}</p>
                                        </div>:null)}
                                    </div>:null)}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Access>
        </div>
	);
};

export default GrilleSaisie;